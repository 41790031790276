import React, {useState} from 'react';
import { navigate } from 'gatsby-link';
import Select from 'react-select';
import {GATSBY_BE_URL} from '../../config/var';


const LogIn = ({setToLocalStorage, overrideLS, storesData, videoData}) => {

  const [password, setPassword] = useState('');
  const [wrongPW, setWrongPW] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  
  const [videoId, setVideoId] = useState(null);
  
  const [selectStore, setSelectStore] = useState(null);
  const [storeName, setStoreName] = useState(1);

  const logInHandler = () => {
    const decodedPw = encodeURIComponent(password);
    setWrongPW(false);
    // TODO done - use 4 testing
    // fetch(`https://sky-roi-staging.stentle.com/wp-json/calderawp/user-exists?email=${decodedPw}`)
    fetch(`${process.env.GATSBY_BE_URL}/wp-json/calderawp/user-exists?email=${decodedPw}`)
    .then(response => response.json())
    .then(data => {
      //console.log(data);
      if (data.exists) {
        setLoggedIn(true);
        setToLocalStorage('SKYpassword', true);
      } else {
        setWrongPW(true);
      }
    });
  }


  const passwordHandler = (e) => {
    setPassword(e.target.value);
  }
  const dataHandler = () => {
      setToLocalStorage('SKYStoreID', selectStore);
      setToLocalStorage('SKYStoreName', storeName);
      setToLocalStorage('SKYvideoId', videoId);
      if (overrideLS) {
        typeof window !== 'undefined' && navigate(`/home?storeID=${selectStore}`);
      } 
  }

  const selectStoresHandler = (e) => {
    setSelectStore(e.value);
    setStoreName(e.label);
  }

  const selectVideoHandler = (e) => {
    setVideoId(e.value);
  }
  



  return (
    <div className="logInCont">
        <div className="logIn">
        <h4>
            {!loggedIn ? 'Please Log In' : 'Select you store'}
        </h4>
        {!loggedIn ? (
            <div>
            <label htmlFor="password">Password:</label>
            <input 
                type="text" 
                name="password" 
                id="password" 
                value={password} 
                onChange={(e) => passwordHandler(e)}
            />
            {wrongPW && <h6 style={{color:'red'}}>your password seems to be incorrect</h6>}
            <button className="logBtn first" onClick={() => logInHandler()}>Log In</button>
            </div>
        ) : (
          <>
            <div>
              <label htmlFor="storeid" style={{margin:'1rem 0 0.5rem', display:'block'}}>StoreId:</label>
              <Select options={storesData} onChange={(e) => selectStoresHandler(e)} />

              <label htmlFor="videoid" style={{margin:'1rem 0 0.5rem', display:'block'}}>Screensaver:</label>
              <Select options={videoData} onChange={(e) => selectVideoHandler(e)} />
            </div>
            <button className="logBtn second" disabled={!(videoId && selectStore)} onClick={() => dataHandler()}>Go to APP!</button>
          </>
        )}
        </div>
    </div>
  )
      
    
}

export default LogIn;